<template>
  <div class="account-login-wraper">
    <!-- <h4 class="form-title">账号密码登录</h4> -->
    <div class="login-form-item">
      <el-input
        v-model="form.username"
        placeholder="请输入账号"
        prefix-icon="el-icon-user"
      ></el-input>
    </div>
    <div class="login-form-item">
      <el-input
        v-model="form.password"
        placeholder="请输入密码"
        prefix-icon="el-icon-lock"
        show-password
        @keyup.enter.native="login"
      ></el-input>
    </div>
    <div class="login-form-item">
      <el-button
        type="primary"
        :loading="loading"
        @click="login"
        class="login-btn"
        >{{ loading ? "登录中..." : "登 录" }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { setToken, RequstToken } from "@/utils/auth";
import { isEmprty } from "@/utils/validate";
import { ShowApiError } from "@/request/error";
export default {
  name: "AccountLoginForm",
  props: {
    redirect: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      if (isEmprty(this.form.username)) {
        this.$message.warning("请先输入用户名");
        return false;
      }
      if (isEmprty(this.form.username)) {
        this.$message.warning("请先输入密码!");
        return false;
      }
      this.loading = true;
      RequstToken(this.form.username, this.form.password)
        .then((res) => {
          let { tokenType, accessToken } = res.data;
          let mToken = `${tokenType} ${accessToken}`;
          setToken(mToken);
          this.loading = false;
          this.$router.push({
            path: this.redirect || "/",
            query: this.$route.query,
          });

          this.$message.success("登录成功");
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("登录错误", err);
        });
    },
  },
};
</script>

<style lang="scss">
.login-form {
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  height: 100%;
  margin: 0 auto;
  padding: 50px;
  padding-top: 60px;
  padding-bottom: 0;
  overflow-y: hidden;
  .form-title {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    text-align: left;
    margin-bottom: 40px;
    background-image: -webkit-linear-gradient(right, #03a1ea, #4ab2e2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .login-form-item {
    box-sizing: border-box;
    margin-bottom: 35px;
    ::v-deep {
      .el-input__inner {
        border-radius: 40px;
        height: 38px;
        line-height: 38px;
        border-color: #ddd;
      }
    }
  }
  .login-btn {
    box-sizing: border-box;
    width: 100%;
    border-radius: 40px;
  }
  .other-login-item {
    box-sizing: border-box;
    padding: 10px 0;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    color: #000;
    .other-login-title {
      padding-right: 20px;
    }
    .other-login-icon {
      font-size: 24px;
      transition: all 0.3s linear;
      cursor: pointer;
      color: #989898;
      &.wechat {
        &:hover {
          color: #52c41a;
        }
      }
    }
  }
}
</style>
